<template>
  <div class="features-product__root">
    <div
      v-for="item in features"
      :key="`features-product-${item.slug}`"
    >
      <div @click="navigateToLink(item)"> {{ item.value }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  ProductCharacteristics,
  ProductCharacteristicValue
} from '#sitis/internal/controllers/products/models/Product';

const props = defineProps<{
	featuresProps: ProductCharacteristics[];
	characteristicGroupId: number | null;
}>();

const features = computed(() => props.featuresProps?.[0]?.characteristics?.[0].values || []);

const navigateToLink = (item: ProductCharacteristicValue) => {
  const link = `/realty/${getCharacteristicGroupSlug.value}/f/${item.slug}=1`;

  navigateTo(link);
};
const getCharacteristicGroupSlug = computed(() => {
  switch (props.characteristicGroupId) {
    case 1:
      return 'flat';
    case 2:
      return 'parking/parking';
    case 3:
      return 'kladovye/kladovye-vse';
    case 4:
      return 'kommercheskie/kommercheskie';
    case 5:
      return 'parking/parking';
    default:
      return 'flat';
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.features-product__root {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	gap: 8px;
	flex-wrap: wrap;

	& > div {
		border: 1px solid var(--primary-15);
		border-radius: 48px;
		padding: 8px 16px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px; /* 100% */
		letter-spacing: -0.16px;
		position: relative;
		z-index: 20;
		cursor: pointer;
	}
}

@include media('md') {
	.features-product__root {
		gap: 4px;

		& > div {
			padding: 4px 16px;
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 160% */
			letter-spacing: -0.1px;
		}
	}
}
</style>
