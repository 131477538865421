import type { ProductCharacteristic } from '#sitis/internal/controllers/products/models/Product';

export type ProductCharacteristicsMainType = {
    label:string;
    value:string;
    className?: string;
    name:string;
}

const getProductLabel = (characteristicGroupId:number):string => {
  if (characteristicGroupId === 2) {
    return 'Паркинг';
  }
  if (characteristicGroupId === 3) {
    return 'Кладовая';
  }
  if (characteristicGroupId === 4) {
    return 'Помещение';
  }
  if (characteristicGroupId === 5) {
    return 'Машиноместо';
  }

  return 'Квартира';
};

const getProductCharacteristicsMain = (characteristics: ProductCharacteristic[]):ProductCharacteristicsMainType[] => {
  const list:ProductCharacteristicsMainType[] = [];

  // Проект
  const project = characteristics.find((t) => t.slug === 'project-name')?.values?.[0]?.value || null;

  if (project) {
    list.push({
      label: 'Проект:',
      value: project,
      className: '--full',
      name: 'project'
    });
  }

  // Срок сдачи
  const houseDevelopmentEnd = characteristics.find((t) => t.slug === 'house-development-end')?.values?.[0]?.value || null;
  if (houseDevelopmentEnd) {
    list.push({
      label: 'Срок сдачи:',
      value: houseDevelopmentEnd,
      name: 'houseDevelopmentEnd'
    });
  }

  // Номер секции / подъезда
  const sectionName = characteristics.find((t) => t.slug === 'section-name')?.values?.[0]?.value || null;
  if (sectionName) {
    list.push({
      label: 'Cекция:',
      value: `№${sectionName}`,
      name: 'sectionName'
    });
  }

  // Этаж
  const floorNumber = characteristics.find((t) => t.slug === 'floor-number')?.values?.[0]?.value || null;

  // максимальный этаж
  const houseMaxFloor = characteristics.find((t) => t.slug === 'house-max-floor')?.values?.[0]?.value || null;
  if (floorNumber) {
    list.push(
      {
        label: 'Этаж:',
        value: `${floorNumber}`,
        name: 'floorNumber'
      },
      {
        label: 'Этаж:',
        value: `${floorNumber} <span>/ ${houseMaxFloor || '-'}</span>`,
        name: 'floorNumberMax'
      }
    );
  }

  // Отделка
  const facing = characteristics.find((t) => t.slug === 'facing')?.values?.[0]?.value || null;
  if (facing) {
    list.push({
      label: 'Отделка:',
      value: facing,
      name: 'facing'
    });
  }

  // площадь кв
  const propertyArea = characteristics.find((t) => t.slug === 'area-total')?.values?.[0]?.value || null;
  if (propertyArea) {
    list.push({
      label: 'Площадь:',
      value: `${propertyArea} м²`,
      name: 'propertyArea'
    });
  }

  // комнаты
  const productRooms = (characteristics || []).find((t) => t.slug === 'rooms')?.values?.[0].value || null;
  if (productRooms) {
    list.push({
      label: 'Комнатность:',
      value: productRooms,
      name: 'productRooms'
    });
  }
  // Название проекта
  const privateHouseName = (characteristics || []).find((t) => t.slug === 'private-house-name')?.values?.[0].value || null;
  if (privateHouseName) {
    list.push({
      label: 'Дом:',
      value: privateHouseName,
      name: 'privateHouseName'
    });
  }

  // ВЫСОТА ПОТОЛКОВ
  const ceilingHeight = (characteristics || []).find((t) => t.slug === 'ceiling-height')?.values?.[0].value || null;
  if (ceilingHeight) {
    list.push({
      label: 'Высота потолков:',
      value: `${ceilingHeight} м`,
      name: 'ceilingHeight'
    });
  }

  // Этаж планировки
  const groupPlaningFloor = (characteristics || []).find((t) => t.slug === 'group_planing_floor')?.values || null;
  if (groupPlaningFloor) {
    list.push({
      label: 'Этаж:',
      value: `${groupPlaningFloor.map((t) => t.value).join(' ,')}`,
      name: 'groupPlaningFloor'
    });
  }

  // Цена планировки
  const groupPlaningPrice = (characteristics || []).find((t) => t.slug === 'group_planing_price')?.values?.[0].value || null;
  if (groupPlaningPrice) {
    list.push({
      label: 'Цена планировки:',
      value: `${groupPlaningPrice}`,
      name: 'groupPlaningPrice'
    });
  }

  // Кол-во планировок
  const groupPlaningCount = (characteristics || []).find((t) => t.slug === 'group_planing_count')?.values?.[0].value || null;
  if (groupPlaningCount) {
    list.push({
      label: 'Кол-во планировок',
      value: `${groupPlaningCount}`,
      name: 'groupPlaningCount'
    });
  }
  return list;
};

export {
  getProductLabel,
  getProductCharacteristicsMain
};
