<template>
  <div class="details-share">
    <div
      class="details-btn"
      @click="onToggleShareBlock"
    >
      <img
        src="~/assets/img/svg/catalog/share.svg"
        alt="plan"
      >
      <span>Поделиться</span>
    </div>
    <div
      v-if="isShownShareBlock"
      class="share-block"
    >
      <div class="share-block-title">
        <div>Поделиться</div>
        <img
          src="~/assets/img/svg/common/close-black.svg"
          alt="close"
          @click="onToggleShareBlock"
        >
      </div>
      <div class="share-block-links">
        <NuxtLink
          target="_blank"
          :to="`https://vk.com/share.php?url=${currentUrl}`"
          class="share-block-link"
        >
          <img
            src="~/assets/img/svg/social/vk.svg"
            alt=""
          >
          <span>VK</span>
        </NuxtLink>
        <!--        <NuxtLink-->
        <!--          target="_blank"-->
        <!--          :to="`https://connect.ok.ru/offer?url=${currentUrl}`"-->
        <!--          class="share-block-link"-->
        <!--        >-->
        <!--          <img-->
        <!--            src="~/assets/img/svg/social/OK.svg"-->
        <!--            alt=""-->
        <!--          >-->
        <!--          <span>Одноклассники</span>-->
        <!--        </NuxtLink>-->
        <NuxtLink
          class="share-block-link"
          target="_blank"
          :to="`https://telegram.me/share/url?url=${currentUrl}`"
        >
          <img
            src="~/assets/img/svg/social/tg-no-bg.svg"
            alt=""
          >
          <span>Telegram</span>
        </NuxtLink>
        <NuxtLink
          :to="`https://wa.me/?text=${currentUrl}`"
          class="share-block-link"
          target="_blank"
        >
          <img
            src="~/assets/img/svg/social/wa-no-bg.svg"
            alt=""
          >
          <span>Whatsapp</span>
        </NuxtLink>
      </div>
      <!--      <client-only>-->
      <!--        <div class="share-block-copy">-->
      <!--          <span>Скопировать ссылку</span>-->
      <!--          <img-->
      <!--            src="~/assets/img/svg/common/copy.svg"-->
      <!--            alt="copy"-->
      <!--            @click="copyUrlFromClipboard"-->
      <!--          >-->
      <!--        </div>-->
      <!--      </client-only>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from '#imports';

const props = defineProps<{
	linkProps?:string;
}>();

let currentUrl: string;

const isShownShareBlock = ref<boolean>(false);
const onToggleShareBlock = () => {
  isShownShareBlock.value = !isShownShareBlock.value;
};

const copyUrlFromClipboard = async () => {
  try {
    await navigator.clipboard.writeText(window.location.href);
    onToggleShareBlock();
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};
watch(() => props.linkProps, (newValue, oldValue) => {
  if ((newValue !== oldValue) && props.linkProps) {
	    currentUrl = props.linkProps;
  }
});
onMounted(() => {
  currentUrl = props.linkProps ? props.linkProps : window.location.href;
});

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.details-share {
	position: relative;
}

.details-btn {
	display: flex;
	align-items: center;
	gap: 10px;
  cursor: pointer;
  justify-content: center;
  border-radius: 22px;
  background-color: var(--white);
  padding: 10px 15px;
  height: 40px;
  box-shadow: 0 4px 5px 0 #ddd2d2;

	& > img {
		width: 20px;
		height: 20px;
	}

	& > span {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}
}

.share-block {
	max-width: 326px;
	width: 100%;
	padding: 20px;
	border: 1px solid var(--stroke);
	background: var(--white);
	box-shadow: 0 1px 10px 0 rgb(4 78 146 / 12%);
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: absolute;
	right: 0;
	top: calc(100% - -4px);
	z-index: 4;
	min-width: 250px;

	&-title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > div {
			color: var(--primary);
			font-weight: 700;
			font-size: 16px;
		}

		& > img {
			width: 15px;
			cursor: pointer;
			filter: invert(9%) sepia(54%) saturate(5441%) hue-rotate(260deg) brightness(86%) contrast(91%);
		}

	}

	&-links {
		padding-left: 10px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&-link {
		display: flex;
		align-items: center;
		gap: 15px;
		width: 100%;

		& > img {
			filter: invert(9%) sepia(54%) saturate(5441%) hue-rotate(260deg) brightness(86%) contrast(91%);
		}

		& > span {
			color: var(--black);
			font-size: 14px;
			font-weight: 500;
		}

		&:hover{
			opacity: .7;
		}
	}

	&-copy {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > span {
			color: var(--gray);
			font-size: 12px;
			font-weight: 400;
			margin-bottom: 5px;
			padding-left: 20px;
		}

		& > img {
			cursor: pointer;
			filter: invert(50%) sepia(1%) saturate(0%) hue-rotate(111deg) brightness(90%) contrast(91%);

			&:hover {
				filter: invert(14%) sepia(94%) saturate(2753%) hue-rotate(194deg) brightness(92%) contrast(104%);
			}
		}
	}
}
</style>
